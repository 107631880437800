.TiditContainer {
    padding: 10px;
    border: 1px solid #ccc;
}

.TiditToolbar {
    margin-bottom: 10px;
}

.Editor {
    min-height: 400px;
}

.DropdownDelayText {
    margin-left: 4px;
}

.ToolbarSmall {
    font-size: 0.875rem;
}

.DisclaimerNote {
    font-size: 0.6rem;
    display: block;
}